<div class="box box1">
    <div class="homeContent">
        <h1 style="text-align: center;">Kapcsolat</h1>
        <p style="text-align: center;">
            <strong>Irodánk elérhetőségei: </strong>
        </p>
        <p style="text-align: center;">
            <b>E-mail: </b> <a href="javascript:;" data-enc-email="pmhcevxnhgbfvfxbyn[at]tznvy.pbz" class="mail-link" data-wpel-link="ignore">
                <span>rozsaautosiskola@gmail.com</span>
            </a>
            <br>
            <b>Telefon/Fax: </b> (68) 631-588
            <br>
            <b>Cím:</b> 5900 Orosháza, Jászai H. u. 52/a
        </p>
        <p style="text-align: center;">
            <b>Ügyfélfogadási idő: </b>
            <br>
            Hétfő - Csütörtök: 18:00 - 20:00</p>
        <p style="text-align: center;">
            <b>Iskolavezető: </b>
            <br>
            Rózsa Tibor
            <br>
            06-30/254-05-82
        </p>
    </div>
    <div class="footer"></div>
</div>