<div class="box box1">
    <div class="homeContent">
        <h1 style="text-align: center;">Oktatóink</h1>
        <div style="display: inline-block; width: 100%;">
            <div class="card">
                <div class="card-box">
                    <div>
                        <img src="assets\images\oktatok\rozsaedina\profil.jpg"/>
                    </div>
                    <div class="name">
                        Beszeda-Rózsa Edina
                    </div>
                    <div class="details">
                        <p>elméleti és gyakorlati oktató</p>
                        <p>Nissan Primera</p>
                        <p class="tel">+36-30/571-58-81</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-box">
                    <div>
                        <img src="assets\images\oktatok\dunaandras\profil.jpg"/>
                    </div>
                    <div class="name">
                        Duna András
                    </div>
                    <div class="details">
                        <p>elméleti és gyakorlati oktató</p>
                        <p>Seat Ibiza</p>
                        <p class="tel">+36-30/811-53-47</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-box">
                    <div>
                        <img src="assets\images\oktatok\rozsatibor\profil.jpg"/>
                    </div>
                    <div class="name">
                        Rózsa Tibor
                    </div>
                    <div class="details">
                        <p>iskolavezető, elméleti és gyakorlati oktató</p>
                        <p>Nissan Note</p>
                        <p class="tel">+36-30/254-05-82</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-box">
                    <div>
                        <img src="assets\images\oktatok\seresistvan\profil.jpg"/>
                    </div>
                    <div class="name">
                        Seres István
                    </div>
                    <div class="details">
                        <p>elméleti és gyakorlati oktató</p>
                        <p>Skoda Fabia</p>
                        <p class="tel">-</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer"></div>
</div>