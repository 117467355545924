<div class="box box1">
    <div class="homeHeaderImg">
    </div>
    <div class="homeContent">
        <h1 style="text-align: center;">Bemutatkozás</h1>
        <p>Iskolánk 2001 õszén alapult meg, s kezdte meg eddigi sikeres mûködését. Célunk azóta változatlan, és töretlen,
            mégpedig hogy tanulóinknak ne csak egy jogosítványt adjunk, hanem, hogy vértezzük fel õket olyan tudással, mellyel
            el tudnak boldogulni a való élet nyújtotta problémákkal, s mindemellett törekszünk minden tanfolyamon családias
            hangulatot kialakítani, így a tanuló-oktató viszonyt barátiasabbá tenni.</p>
        <p>Az oktatás színesítéséért és a jobb megértésért TV, videó, DVD és számítógépes gyakorlás, valamint projektoros
            vetítés segíti tanulóink munkáját, de ez mindsem elegendõ, ha a tanuló otthoni szorgalmával ehhez nem járul hozzá.</p>
        <p>Tananyagaink külön részekre bomlanak, melyeket külön ezekre a részekre szakosodott oktatóink tanítanak, ezáltal a
            maximumra törekedve.</p>
        <p>Az oktatás részei: elméleti oktatás; egészségügyi oktatás; mûszaki oktatás; gyakorlati oktatás
            (Az oktatók neveit és foglalkozáskörüket az "Oktatóinkról" címû lapon találják.)</p>
        <p>Iskolánk az indulás óta folyamatosan terjeszkedik, fejlõdik, ezért tanfolyamok indulnak (fõleg) Orosházán, Csorváson.
            (Tanfolyamok részletezve a "Tanfolyamok" címû lapon.)</p>
        <p>Remélem ez a kis bemutatkozó megnyerte tetszését és jogosítványszerzés céljából iskolámat fogja választani.</p>
        <p class="signature">Tisztelettel: Rózsa Tibor, iskolavezetõ</p>
    </div>
    <div class="footer"></div>
</div>