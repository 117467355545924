import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InstructorsComponent } from './instructors/instructors.component';
import { CoursesComponent } from './courses/courses.component';
import { PricesComponent } from './prices/prices.component';
import { InformationComponent } from './information/information.component';
import { ContactsComponent } from './contacts/contacts.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: 'home', component: HomeComponent, data: {animation: 'Iskolankrol'} },
  { path: 'instructors', component: InstructorsComponent, data: {animation: 'Oktatok'} },
  { path: 'courses', component: CoursesComponent, data: {animation: 'Tanfolyamok'} },
  { path: 'prices', component: PricesComponent, data: {animation: 'Arak'} },
  { path: 'information', component: InformationComponent, data: {animation: 'Informaciok'} },
  { path: 'contacts', component: ContactsComponent, data: {animation: 'Kapcsolat'} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
