<div class="box box1">
    <div class="homeContent">
        <h1 style="text-align: center;">Árak</h1>
        <p>Tanfolyam Díjak "B" kategóriában:</p>
        <table>
            <tr>
                <td>Elmélet</td>
                <td>Orosháza</td>
                <td>35.000 Ft</td>
            </tr>
            <tr>
                <td>Elmélet</td>
                <td>Csorvás</td>
                <td>35.000 Ft</td>
            </tr>
            <tr>
                <td>Gyakorlati óra</td>
                <td>8.000 Ft</td>
                <td></td>
            </tr>
            <tr>
                <td>Egészségügyi tanfolyam (EÜ)</td>
                <td>25.000 Ft</td>
                <td></td>
            </tr>
            <tr>
                <td>Egészségügyi Vizsga Díj (csekk)</td>
                <td>18.000 Ft</td>
                <td></td>
            </tr>
            <tr>
                <td>Vizsgadíjak</td>
                <td>Elméleti vizsgadíj:</td>
                <td>4.600 Ft</td>
            </tr>
            <tr>
                <td></td>
                <td>JK (Rutin vizsgadíj)</td>
                <td>4.700 Ft</td>
            </tr>
            <tr>
                <td></td>
                <td>Gyakorlati vizsgadíj</td>
                <td>11.000 Ft</td>
            </tr>
            <tr>
                <td>Korrepetáció</td>
                <td>Ingyenes</td>
                <td></td>
            </tr>
            <tr>
                <td>Számítógéphasználat</td>
                <td>Ingyenes</td>
                <td></td>
            </tr>
        </table>
        <p>A pót óra díjak megegyeznek a normál órák díjával.</p>
    </div>
    <div class="footer"></div>
</div>