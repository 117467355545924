<div class="box box1">
    <div class="tanteremHeaderImg">
    </div>
    <div class="homeContent">
        <h1 style="text-align: center;">Tanfolyamok</h1>
        <p>Tanfolyamaink családias hangulatban zajlanak, ahogy azt az "Iskolánkról..." címû részben is említettük,
            valamint azt is, hogy tantermünk jól felszerelt. Az oktatást TV, videó, DVD és számítógép alkalmazásával,
            valamint projektoros vetítéssel történik, de a számítógépet a tanulók a tanfolyam elõtt - esetleg után -
            egyaránt szabadon használhatják.</p>
        <p>Képzéseink szinte kivétel nélkül hétvégén folynak szombati és vasárnapi napokon, de kivételt képez ezalól a
            nyári intenzív tanfolyam, mely heti 3 alkalmat, pénteki, szombati és vasárnapi napokat jelentenek.</p>
        <p>Indítunk tanfolyamokat "K", "A1", "A" és "B" kategóriákban:</p>
        <table>
            <tr>
                <td>Időpont</td>
                <td>Oktató(k)</td>
                <td>Helye</td>
                <td>Elérhetőségek</td>
            </tr>
            <tr>
                <td>Folyamatosan</td>
                <td>Rózsa Tibor</td>
                <td>Orosháza, Jászai H. utca 52/a</td>
                <td><div>Rózsa Tibor: 06-30/254-0582</div><div>Rózsa Tibor: 06-68/631-588</div></td>
            </tr>
        </table>

        <h2 class="elearning">
            E-learning bemutató
        </h2>
        <p>
            <a href="https://nexiuscontent.blob.core.windows.net/public/cd653863-e3e0-f300-a71b-669802f5ad28/00636464-4373-0738-6015-00008c8c27ea/index.html" target="_blank" rel="noopener"><img src="assets/images/edukresz-logo.png" alt="edukresz logo"></a>
        </p>
        <p>
            <strong>
                <a href="https://nexiuscontent.blob.core.windows.net/public/cd653863-e3e0-f300-a71b-669802f5ad28/00636464-4373-0738-6015-00008c8c27ea/index.html" target="_blank" rel="noopener">A bemutató ide kattintva érhető el</a>
            </strong>
        </p>
    </div>
    <div class="footer"></div>
</div>