<header>
  <div class="logo">
    <a href="/" title="Rózsa Autósiskola">
      <div>
        <div>
          <p class="line1">Rózsa</p>
          <p class="line2">Au<span class="t">t</span>ósiskola</p>
          <p class="line3">Gépjárművezetői tanfolyamok</p>
        </div>
      </div>
    </a>
  </div>
  <div>
    <div>
      <div><a routerLink="home" routerLinkActive="active">Iskolánkról</a></div>
      <div><a routerLink="instructors" routerLinkActive="active">Oktatók</a></div>
      <div><a routerLink="courses" routerLinkActive="active">Tanfolyamok</a></div>
      <div><a routerLink="prices" routerLinkActive="active">Árak</a></div>
      <div><a routerLink="information" routerLinkActive="active">Információk</a></div>
      <div><a routerLink="contacts" routerLinkActive="active">Kapcsolat</a></div>
    </div>
  </div>
</header>

<div [@routeAnimations]="o && o.activatedRouteData 
      && o.activatedRouteData['animation']">
  <router-outlet #o="outlet"></router-outlet>
</div>